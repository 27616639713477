@import '~@recommendations-fe/card/src/styles/clients/recommendations-fe-card-horizontal--for-cart-v4.mobile';

.#{$app-prefix}-card-featured {
  margin-bottom: $andes-spacing-12;
  margin-bottom: 0;

  .poly-card--list.poly-card--mobile {
    border-bottom: none;
  }

  &:not(.#{$app-prefix}-card-featured-sm) .poly-card.poly-card--mobile {
    --poly-min-width: 144px;
  }

  .poly-component__action-links {
    margin-bottom: 6px;
  }

  .poly-action-links__action {
    width: 90%;
    height: 32px;
    border-color: transparent;
    background-color: $andes-blue-500;
    border-radius: 5px;
    box-shadow: unset;
    transition: box-shadow 0.25s ease-out, background-color 0.2s ease-out;
    cursor: pointer;

    .poly-component__link--action-link {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $andes-white;
    }
  }
}
